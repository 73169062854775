import React from "react"
import "./style.css"

const Input = ({ isTextArea, hasError, ...props }) => {
    return !isTextArea ? (
        <div className={`input__wrapper ${hasError && "hasError"}`}>
            <input className="input__wrapper--input" {...props} />
        </div>
    ) : (
        <div className={`input__wrapper ${hasError && "hasError"}`}>
            <textarea className="input__wrapper--textarea" {...props} />
        </div>
    )
}

export default Input
