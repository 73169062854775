import React, { useState } from "react"
import "./style.css"

import Input from "../Input"
import Button from "../Button"

const PartnerForm = ({ isVisible, onClose }) => {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [error, setError] = useState(null)

    const initialState = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        company: "",
        website: "",
        subject: "",
        comments: ""
    }
    const [formData, setFormData] = useState(initialState)
    const requiredFields = ["firstName", "lastName", "email", "phone"]

    const encode = data => {
        return Object.keys(data)
            .map(
                key =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(data[key])
            )
            .join("&")
    }

    const validateForm = () => {
        let isValid = true
        for (const field of requiredFields) {
            if (!formData[field]) {
                isValid = false
            }
        }
        return isValid
    }
    const onSubmit = e => {
        e.preventDefault()
        setIsSubmitted(false)
        setError(null)

        if (validateForm()) {
            fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: encode({ "form-name": "become_a_partner", ...formData })
            })
                .then(() => {
                    setIsSubmitted(true)
                    setFormData(initialState)
                    setTimeout(() => {
                        setIsSubmitted(false)
                        onClose()
                    }, 2000)
                })
                .catch(error => alert(error))
        } else {
            setError("You must fill required fields")
            setTimeout(() => setError(null), 3000)
        }
    }

    const setData = (type, value) => {
        const obj = { ...formData }
        obj[type] = value
        setFormData(obj)
    }

    const onCloseClick = () => {
        onClose()
        setIsSubmitted(false)
    }

    return (
        isVisible && (
            <div className="aray__partner--background">
                <form
                    name="become_a_partner"
                    className="aray__partner-form__wrapper"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={onSubmit}
                >
                    <input
                        type="hidden"
                        name="form-name"
                        value="become_a_partner"
                    />
                    <h2 className="aray__partner-form__title">
                        Partner to shape the future of female golf
                    </h2>
                    <>
                        <div className="aray__partner-form___name">
                            <Input
                                placeholder="First name *"
                                name="firstName"
                                onChange={({ target }) =>
                                    setData("firstName", target.value)
                                }
                                value={formData.firstName}
                            />

                            <Input
                                placeholder="Last name *"
                                name="lastName"
                                value={formData.lastName}
                                onChange={({ target }) =>
                                    setData("lastName", target.value)
                                }
                            />
                        </div>
                        <Input
                            placeholder="Phone *"
                            value={formData.phone}
                            name="phone"
                            onChange={({ target }) =>
                                setData("phone", target.value)
                            }
                        />

                        <Input
                            placeholder="Email *"
                            value={formData.email}
                            name="email"
                            type="email"
                            onChange={({ target }) =>
                                setData("email", target.value)
                            }
                        />
                        <Input
                            placeholder="Company"
                            value={formData.company}
                            name="company"
                            onChange={({ target }) =>
                                setData("company", target.value)
                            }
                        />
                        <Input
                            placeholder="Website"
                            value={formData.website}
                            name="website"
                            onChange={({ target }) =>
                                setData("website", target.value)
                            }
                        />
                        <Input
                            placeholder="Subject"
                            name="subject"
                            value={formData.subject}
                            onChange={({ target }) =>
                                setData("subject", target.value)
                            }
                        />
                        <Input
                            placeholder="Comments"
                            isTextArea
                            name="comments"
                            value={formData.comments}
                            onChange={({ target }) =>
                                setData("comments", target.value)
                            }
                        />
                    </>
                    {isSubmitted && (
                        <h3 className="success-registration">
                            Success! Information was sent.
                        </h3>
                    )}
                    {error && (
                        <h3 className="error-message-form">
                            You must fill required fields
                        </h3>
                    )}

                    <div className={`aray__partner--button`}>
                        <Button onClick={onSubmit}>Become a partner</Button>

                        <Button secondary onClick={() => onCloseClick()}>
                            {isSubmitted ? "Close" : "Cancel"}
                        </Button>
                    </div>
                </form>
            </div>
        )
    )
}

export default PartnerForm
