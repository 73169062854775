import React, { useState } from "react"
import TopSection from "../components/TopSection"
import Layout from "../components/layout"
import Seo from "../components/seo"
import marbella from "../images/marbella.png"
import Button from "../components/Button"
import PartnerSection from "../section/Partner"
import PartnerForm from "../components/FormPartner"

const Partner = () => {
    const [isPartnerFormVisible, setIsPartnerFormVisible] = useState(false)
    return (
        <Layout>
            <Seo title="Become a partner" />
            <TopSection image={marbella}>
                <div className="signup-section__left">
                    <h1>Let’s shape the future of female golf together</h1>
                    <p className="signup-section__left__description">
                        ARAY Society’s success is built on great partnerships,
                        these are some ways to partner up with us and our
                        community:
                    </p>
                    <p className="signup-section__left__description">
                        <span className="bold">Title partner</span> - Exclusive
                        title partners at ARAY's biggest events during the year,
                        specially featured in all communication
                    </p>
                    <p className="signup-section__left__description">
                        <span className="bold">Affiliated partner</span> -
                        Featured in the platform with offers towards the
                        community, featured in SoMe channels, possibility to
                        have goodie bags and opportunities to be featured at
                        event
                    </p>
                    <p className="signup-section__left__description">
                        <span className="bold">Strategic partner</span> - Actors
                        with solutions or products that leverages ARAY's
                        technical solution or member offer
                    </p>
                    <p className="signup-section__left__description">
                        <span className="bold">Sponsor specific events</span> -
                        Choose to get featured on one or several events during
                        the year or add on a specific event to existing
                        partnership
                    </p>
                    <p className="signup-section__left__description">
                        Are you interested? Contact us for more information
                    </p>
                    <Button
                        className="signup-section__signup-btn"
                        onClick={() => setIsPartnerFormVisible(true)}
                    >
                        BECOME A PARTNER
                    </Button>
                </div>
            </TopSection>
            <PartnerSection showAll />
            <PartnerForm
                isVisible={isPartnerFormVisible}
                onClose={() => setIsPartnerFormVisible(false)}
            />
        </Layout>
    )
}

export default Partner
